<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                <li class="breadcrumb-item">
                  <a href="#" target="_self" class="">
                    <a href="#/" class="active"><i class="fas fa-home"></i> </a>
                  </a>
                </li>
                <li class="breadcrumb-item" style="display: inline-block">
                  <router-link
                    class="active"
                    :to="{ name: 'ListTrafficProject' }"
                    >My Projects</router-link
                  >
                </li>
                <li
                  class="breadcrumb-item active"
                  style="display: inline-block"
                >
                  <span aria-current="location"
                    ><span data-v-99912124="">{{ project.name }}</span></span
                  >
                </li>
              </ol>
            </nav>
          </nav>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">
            My Project <strong>{{ project.name }}</strong>
          </h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <div
            class="row d-flex align-items-baseline pb-3"
            style="
              border-bottom: 1px solid rgba(0, 0, 0, 0.05);
              margin-bottom: 10px;
            "
          >
            <div class="col-md-4 my-3">
              <strong>Total Hits Sent: </strong>
              {{ totalHits | formatNumber }} hits
            </div>
            <div
              class="col-md-8 d-flex flex-row justify-content-end align-items-baseline"
            >
              <date-range-picker
                ref="picker"
                :opens="'left'"
                :locale-data="localeData"
                :timePicker="false"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="true"
                v-model="dateRange"
                :ranges="rangeOptions"
                @update="applyDateRange"
                :linkedCalendars="true"
                :control-container-class="'form-control form-control-sm datepicker flatpickr-input'"
              >
                <template v-slot:input="picker" style="min-width: 350px">
                  {{ picker.startDate | formatDate }} -
                  {{ picker.endDate | formatDate }} </template
                >i
              </date-range-picker>

              <base-button
                class="view btn-icon ml-1"
                type="primary"
                size="sm"
                icon
                @click="getTrafficProject"
              >
                <font-awesome-icon icon="filter" inverse /> Lọc
              </base-button>
            </div>
          </div>

          <!-- Form groups used in grid -->
          <div class="row">
            <div class="w-100">
              <highcharts class="hc" :options="chartOptions"></highcharts>
            </div>
          </div>
        </div>
      </div>

      <!-- Form Edit project  -->
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">My project</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <form role="form">
            <tabs tabNavClasses="nav-fill flex-column flex-sm-row">
              <tab-pane title="Basic settings" class="mt-4">
                <div class="row">
                  <div class="col-md-12">
                    <base-input
                      v-model="project.name"
                      :inputClasses="errors.get('name') ? 'is-invalid' : ''"
                      label="Tên dự án"
                      :errors="errors.get('name') ? [errors.get('name')] : []"
                      disabled
                    >
                    </base-input>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <base-input label="Size">
                      <el-select v-model="project.size" disabled>
                        <el-option value="mini" label="Mini" />
                        <el-option value="medium" label="Medium" />
                      </el-select>
                    </base-input>
                  </div>

                  <div class="col-md-6">
                    <base-input label="Time on each page">
                      <el-select v-model="project.time_on_page" filterable>
                        <el-option
                          v-for="option in timeOnPageOptions"
                          :key="option.label"
                          :label="option.label"
                          :value="option.value"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <base-input label="Speed">
                      <base-slider v-model="project.speed"></base-slider>
                      <div class="mt-3 row">
                        <div class="col-6">
                          <span class="range-slider-value"
                            >{{ project.speed }}%</span
                          >
                        </div>
                      </div>
                    </base-input>
                  </div>

                  <div class="col-md-6">
                    <base-input label="Bounce rate">
                      <base-slider v-model="project.bounce_rate"></base-slider>
                      <div class="mt-3 row">
                        <div class="col-6">
                          <span class="range-slider-value"
                            >{{ project.bounce_rate }}%</span
                          >
                        </div>
                      </div>
                    </base-input>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <base-input label="Return rate">
                      <base-slider
                        v-model="project.returningvisitors"
                      ></base-slider>
                      <div class="mt-3 row">
                        <div class="col-6">
                          <span class="range-slider-value"
                            >{{ project.returningvisitors }}%</span
                          >
                        </div>
                      </div>
                    </base-input>
                  </div>

                  <div class="col-md-6">
                    <base-input label="Devices">
                      <el-select v-model="project.behaviour">
                        <el-option
                          v-for="option in devicesOptions"
                          :key="option.label"
                          :label="option.label"
                          :value="option.value"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
              </tab-pane>
              <tab-pane title="URLS" class="mt-4">
                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="URLs"
                      :errors="errors.get('urls') ? [errors.get('urls')] : []"
                    >
                      <textarea
                        v-model="project.urls"
                        :class="[
                          errors.get('urls') ? 'is-invalid' : '',
                          'form-control',
                        ]"
                        rows="5"
                      ></textarea>
                    </base-input>
                  </div>

                  <div class="col-md-6">
                    <base-input
                      label="1st Click URLs"
                      :errors="
                        errors.get('second_urls')
                          ? [errors.get('second_urls')]
                          : []
                      "
                    >
                      <textarea
                        v-model="project.second_urls"
                        :class="[
                          errors.get('second_urls') ? 'is-invalid' : '',
                          'form-control',
                        ]"
                        rows="5"
                      ></textarea>
                    </base-input>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <base-input
                      label="2nd Click URLs"
                      :errors="
                        errors.get('third_urls')
                          ? [errors.get('third_urls')]
                          : []
                      "
                    >
                      <textarea
                        v-model="project.third_urls"
                        :class="[
                          errors.get('third_urls') ? 'is-invalid' : '',
                          'form-control',
                        ]"
                        rows="5"
                      ></textarea>
                    </base-input>
                  </div>
                </div>
              </tab-pane>
              <tab-pane title="Target Settings" class="mt-4">
                <div class="row">
                  <div class="col-md-6">
                    <base-input label="Traffic Type">
                      <el-select v-model="project.traffic_type">
                        <el-option
                          v-for="option in trafficTypeOptions"
                          :key="option.label"
                          :label="option.label"
                          :value="option.value"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>

                  <div class="col-md-6">
                    <base-input
                      label="Referrers"
                      :errors="
                        errors.get('referrers') ? [errors.get('referrers')] : []
                      "
                      v-if="project.traffic_type === 'referral'"
                    >
                      <textarea
                        v-model="project.referrers"
                        :class="[
                          errors.get('referrers') ? 'is-invalid' : '',
                          'form-control',
                        ]"
                        rows="5"
                      ></textarea>
                    </base-input>

                    <base-input
                      label="Keywords"
                      :errors="
                        errors.get('keywords') ? [errors.get('keywords')] : []
                      "
                      v-if="project.traffic_type === 'organic'"
                    >
                      <textarea
                        v-model="project.keywords"
                        :class="[
                          errors.get('keywords') ? 'is-invalid' : '',
                          'form-control',
                        ]"
                        rows="5"
                      ></textarea>
                    </base-input>

                    <base-input
                      label="Social Profiles"
                      :errors="
                        errors.get('social_links')
                          ? [errors.get('social_links')]
                          : []
                      "
                      v-if="project.traffic_type === 'social'"
                    >
                      <textarea
                        v-model="project.social_links"
                        :class="[
                          errors.get('social_links') ? 'is-invalid' : '',
                          'form-control',
                        ]"
                        rows="5"
                        placeholder="Full links to your social networks pages, for example, https://www.facebook.com/themostadvancedtrafficbot/, One per line"
                      ></textarea>
                    </base-input>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <base-input label="Languages">
                      <el-select
                        v-model="project.languages"
                        multiple
                        filterable
                      >
                        <el-option
                          v-for="option in languageNameOptions"
                          :key="option.label"
                          :label="option.label"
                          :value="option.value"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>

                  <div class="col-md-6">
                    <base-input label="Countries Geo-Targeting">
                      <el-select
                        v-model="project.traffic_source"
                        multiple
                        filterable
                      >
                        <el-option
                          v-for="option in trafficSourceOptions"
                          :key="option.label"
                          :label="option.label"
                          :value="option.value"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
              </tab-pane>
            </tabs>

            <base-button
              type="button"
              native-type="submit"
              @click.prevent="handleUpdate"
              class="btn btn-primary mt-4"
              >Cập nhật</base-button
            >
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { parseTime } from "@/utils";
import { Table, TableColumn, Select, Option } from "element-ui";
import DateRangePicker from "vue2-daterange-picker";
import BaseSlider from "@/components/BaseSlider";
import Tabs from "@/components/Tabs/Tabs";
import TabPane from "@/components/Tabs/Tab";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Errors from "@/utils/error";
import { formatNumberWithCommas } from "../../../utils";

export default {
  name: "ViewTrafficProject",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    DateRangePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    BaseSlider,
    Tabs,
    TabPane,
  },
  data() {
    let startDate = new Date();
    let endDate = new Date();
    let startDate30Days = new Date();
    let startDate90Days = new Date();
    let startDate180Days = new Date();
    startDate.setDate(endDate.getDate() - 30);
    startDate30Days.setDate(endDate.getDate() - 30);
    startDate90Days.setDate(endDate.getDate() - 90);
    startDate180Days.setDate(endDate.getDate() - 180);

    return {
      picker: {
        startDate: startDate,
        endDate: endDate,
      },
      dateRange: { startDate, endDate },
      localeData: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Chọn",
        cancelLabel: "Huỷ",
        weekLabel: "Tuần",
        customRangeLabel: "Tuỳ chọn ngày",
        daysOfWeek: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
        monthNames: [
          "Th1",
          "Th2",
          "Th3",
          "Th4",
          "Th5",
          "Th6",
          "Th7",
          "Th8",
          "Th9",
          "Th10",
          "Th11",
          "Th12",
        ],
        firstDay: 1,
      },
      rangeOptions: {
        "1 tháng": [startDate30Days, endDate],
        "3 tháng": [startDate90Days, endDate],
        "6 tháng": [startDate180Days, endDate],
      },
      opens: false,
      // colors: colors,
      project: {},
      totalHits: 0,
      timeOnPageOptions: [
        { label: "5 seconds", value: "5sec" },
        { label: "30 seconds", value: "30sec" },
        { label: "1 minute", value: "1min" },
        { label: "2 minutes", value: "2min" },
        { label: "5 minutes", value: "5min" },
      ],
      devicesOptions: [
        { label: "Realistic Behaviour (30% Mobile)", value: "realistic" },
        { label: "Desktop Only (Win & Mac)", value: "desktop" },
        { label: "Mobile Only", value: "mobile" },
        { label: "Completely Random", value: "random" },
      ],
      trafficTypeOptions: [
        { label: "Direct", value: "direct" },
        { label: "Referral", value: "referral" },
        { label: "Social", value: "social" },
        { label: "Organic", value: "organic" },
      ],
      languageNameOptions: [],
      trafficSourceOptions: [],
      query: {
        startDate: null,
        endDate: null,
      },
      showAll: true,
      errors: new Errors(),
      chartOptions: {
        title: {
          text: "",
        },
        chart: {
          styledMode: false,
          type: "area",
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          categories: [],
          labels: {
            step: 3,
            align: "left",
          },
          gridLineWidth: 1,
          tickmarkPlacement: "on",
        },
        yAxis: {
          title: {
            text: null,
          },
        },
        series: [],
        plotOptions: {
          series: {
            cursor: "pointer",
            color: "#6AB8F7",
            fillOpacity: "0.1",
            marker: {
              lineWidth: 1,
            },
          },
        },
        tooltip: {
          formatter: function () {
            return `<strong>${this.point.category}:</strong> ${this.point.y} page views`;
          },
          backgroundColor: "rgba(0, 0, 0, .75)",
          borderWidth: 2,
          style: {
            color: "#CCCCCC",
          },
        },
      },
    };
  },
  created() {
    this.loadLanguages();
    this.loadCountries();
    this.getTrafficProject();
  },
  filters: {
    formatDateTime(date) {
      return parseTime(date, "{d}-{m}-{y} {h}:{i}:{s}");
    },
    formatDate(date) {
      return parseTime(date, "{d}-{m}-{y}");
    },
    formatNumber(num) {
      return formatNumberWithCommas(num);
    },
  },
  methods: {
    async loadLanguages() {
      const languages = await this.$store.dispatch("traffic/getLanguages");

      this.languageNameOptions = _.map(languages, function (language) {
        return { label: language.name, value: language.code };
      });
    },
    async loadCountries() {
      const countries = await this.$store.dispatch("traffic/getCountries");

      this.trafficSourceOptions = _.map(countries, function (country) {
        return { label: country.name, value: country.iso_code };
      });
      this.trafficSourceOptions.push({ label: "Global", value: "global" });
    },
    async getTrafficProject() {
      this.$preloaders.open();

      if (this.query.startDate === null && this.query.endDate === null) {
        this.query.startDate = parseTime(
          this.dateRange.startDate,
          "{y}-{m}-{d}"
        );
        this.query.endDate = parseTime(this.dateRange.endDate, "{y}-{m}-{d}");
      }

      let self = this;
      try {
        const data = await this.$store.dispatch("traffic/fetchProject", {
          id: self.$route.params.id,
          query: self.query,
        });

        this.project = data;
        this.chartOptions.xAxis.categories = data.traffics.labels;
        this.chartOptions.series = [
          {
            data: data.traffics.series,
            marker: {
              fillColor: "white",
              lineWidth: 2,
              lineColor: "#6AB8F7",
            },
          },
        ];
        this.totalHits = data.traffics.series.reduce(
          (acc, curr) => acc + curr,
          0
        );
      } catch (e) {
        console.log(e);
      }

      this.$preloaders.close();
    },
    applyDateRange(value) {
      this.query.startDate = parseTime(value.startDate, "{y}-{m}-{d}");
      this.query.endDate = parseTime(value.endDate, "{y}-{m}-{d}");
      const diffInDays =
        (value.endDate - value.startDate) / (1000 * 60 * 60 * 24);
      // Different step size for different date ranges
      this.chartOptions.xAxis.labels.step =
        diffInDays > 90 ? 12 : diffInDays > 60 ? 6 : 3;
    },
    handleUpdate() {
      this.$preloaders.open();

      let self = this;
      this.submitSuccess = false;
      this.errors = new Errors();
      this.errText = "";
      this.isLoading = true;
      // Remove Vietnamese special character and space from title
      // Ignore for now because title will not be updated
      // self.project.title = removeAccents(self.project.title).replaceAll(/[^\w\s]/gi, '');

      this.$store
        .dispatch("traffic/updateProject", self.project)
        .then(() => {
          this.$notify({
            message: "Cập nhật traffic project thành công!",
            timeout: 2000,
            icon: "ni ni-bell-55",
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "right",
          });
          this.$preloaders.close();
        })
        .catch((err) => {
          if (err.response.status === 422) {
            self.errors.record(err.response.data);
          }

          if (err.response.status === 503 || err.response.status === 500) {
            self.errText =
              "Cập nhật dự án không thành công xin vui lòng thử lại!";
          } else {
            self.errText = "Thông tin nhập vào không đúng, xin thử lại!";
          }

          this.$notify({
            message: self.errText,
            timeout: 2000,
            icon: "ni ni-bell-55",
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "right",
          });

          this.$preloaders.close();
        });
    },
  },
};
</script>

<style>
.custom-checkbox .custom-control-input ~ .custom-control-label {
  font-size: 0.6rem;
  padding-top: 5px;
  word-break: break-all;
}

.highcharts-container {
  width: 100% !important;
}

.highcharts-root {
  width: 100% !important;
}
</style>
